import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { OrderService } from 'services';
import ring from 'assets/ring.mp3';
import './TVScreen.css';

export const TVSCreen = () => {
  const [orders, setOrders] = useState({
    inProcess: [],
    ready: []
  });
  const params = useParams();
  const orderService = new OrderService();
  const audio = new Audio(ring);

  useEffect(() => {
    let firstOrderId = undefined;
    const getOrders = async () => {
      const inProcess = await orderService.getOrderIds({ statusId: [4], branchId: params.id });
      const ready = await orderService.getOrderIds({ statusId: [5], branchId: params.id });
      setOrders({
        inProcess: inProcess.codes,
        ready: ready.codes
      });

      if (firstOrderId !== ready[0]) {
        audio.play();
        firstOrderId = ready[0];
      }
    };

    getOrders();
    const interval = setInterval(() => getOrders(), 15000);
    return () => clearInterval(interval);
  }, [params.id]);

  return (
    <div>
      <div className='title-grid'>
        <h1 className='in-progress'>მზადდება</h1>
        <h1 className='ready'>გამზადებულია</h1>
      </div>
      <div className='numbers-grid'>
        <div className='list-grid'>
          {orders.inProcess.map((order) => (
            <div key={order} className='order-container gray'>
              <p>{order}</p>
            </div>
          ))}
        </div>
        <div className='list-grid'>
          {orders.ready.map((order) => (
            <div key={order} className='order-container red'>
              <p>{order}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
